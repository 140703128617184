<template>
  <div class="large-page">
    <!-- show the breadcrumbs and the button to trigger form -->
    <breadcrumbs>
      <div class="d-flex flex-grow-1 justify-end align-center">
        <v-btn
          fab
          x-small
          depressed
          color="white"
          @click="shouldShowDialog = true"
        >
          <v-icon color="secondary">
            add
          </v-icon>
        </v-btn>
      </div>
    </breadcrumbs>

    <!-- show the results -->
    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      class="mt-4 box-shadow-soft"
    >
      <template v-slot:item.client_name="{ item }">
        {{ item.members.map((_item) => _item.name).join(", ") }}
      </template>
    </v-data-table>

    <!-- show the dialog form -->
    <v-dialog
      v-model="shouldShowDialog"
      max-width="500"
    >
      <v-card
        :loading="isLoading"
      >
        <v-card-text class="pt-4">
          <v-form
            :disabled="isLoading"
          >
            <v-row class="mt-0">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.name"
                  :hide-details="!$v.form.name.$anyError"
                  :error-messages="$v.form.name.$anyError ? ['Company name is required'] : null"
                  @blur="$v.form.name.$touch"
                  autocomplete="company-name"
                  label="Company Name"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.margin"
                  :hide-details="!$v.form.margin.$anyError"
                  :error-messages="$v.form.margin.$anyError ? ['Margin value is required'] : null"
                  @blur="$v.form.margin.$touch"
                  autocomplete="company-margin"
                  label="Add Margin"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            depressed
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            @click="handleSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")

const defaultFormData = () => ({
  name: "",
  margin: ""
})

export default {
  name: "AdminUserRates",

  components: {
    Breadcrumbs
  },

  data: () => ({
    isLoading: false,
    shouldShowDialog: false,

    form: defaultFormData(),

    tableHeaders: [
      {
        text: "ID",
        value: "id",
        sortable: false
      },
      {
        text: "Client Name",
        value: "client_name",
        sortable: false
      },
      {
        text: "Company Name",
        value: "name",
        sortable: false
      },
      {
        text: "Margin",
        value: "margin",
        sortable: false
      },
      {
        text: "Status",
        value: "is_active",
        sortable: false
      },
    ],

    tableItems: []
  }),

  validations: {
    form: {
      name: {},
      margin: {}
    }
  },

  methods: {
    async fetchItems() {
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        const response = await axios({
          url: "/api/admin/companies",
          method: "GET",
        })

        this.tableItems = response.data
      }
      catch (error) {
        logger({ type: "Error while fetching price string options", error })
      }
      finally {
        this.$store.dispatch("loaders/remove", loaderId)
      }
    },

    async handleSubmit() {
      await this.$v.$touch()

      // if the validation fails, don't continue
      if (this.$v.$anyError) return false

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      try {
        await axios({
          url: "/api/admin/companies",
          method: "POST",
          data: {
            name: this.form.name,
            margin: this.form.margin
          }
        })

        // once created
        this.$store.dispatch("toasts/add", { text: "Company margin has been added." })

        // hide the dialog
        this.shouldShowDialog = false
        this.form = defaultFormData()

        // reload the items
        this.fetchItems()
      } catch (error) {
        logger({ type: "Create User Rate Error", error })
      } finally {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    }
  },

  mounted() {
    this.fetchItems()
  }
}
</script>
